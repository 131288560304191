@import '@styles/styles.scss';

.actionsDialogContent {
    .contentWrapper {
        .headline {
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .iconTitle {
            margin-right: 20px;
        }

        .title {
            margin-top: 0;
        }
    }

    .dialogActionGroup {
        flex-direction: column-reverse;

        .btn {
            height: 54px;
            width: 235px;
            margin: 0 20px 10px;
        }
    }

    @include mobileLarge {
        .contentWrapper {
            .iconTitle {
                margin-right: 5px;
            }
        }
    }
}
