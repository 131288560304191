@import '@styles/styles.scss';

.wrapper {
    &.buyPaying,
    &.sellPaying {
        padding-top: 0;
    }
}

.mainInfosWrapper {
    min-width: 600px;

    @include tablet {
        min-width: auto;
    }
}

.resultedWrapper {
    .mainInfosWrapper {
        background-color: $infosBkColor;
    }
}

/*=============================================
=                 Buttons                     =
=============================================*/

.btnGroupWrapper {
    display: flex;
    justify-content: center;

    @include mobile {
        justify-content: center;
    }
}

.btn {
    &.MuiButton-contained {
        svg .fillArea {
            fill: $primaryTextContrast;
        }

        &.Mui-disabled {
            background-color: $primaryColor;
            opacity: 0.8;

            .MuiButton-label {
                color: $primaryTextContrast;
            }
        }
    }

    &.MuiButton-outlined {
        color: $COLOR_STEELGREY;
        background-color: transparent;
        box-shadow: none;
        border: none;

        &:hover {
            border: none;
            box-shadow: none;
            text-decoration: none;
            background-color: rgba($COLOR_BLACK, 0.04);
        }

        .MuiButton-label {
            color: $COLOR_STEELGREY;
        }
    }

    .content .iconAddition {
        display: none;
    }
}

/*=====  End of Buttons  ======*/

.MuiOutlinedInput-root.inputField,
.react-tel-input .inputField {
    border-radius: 0;
}

.itemField.count,
.itemField.userPrice {
    display: none;
}

.itemField {
    &.unitCount,
    &.realAmount {
        .unit,
        .numeric {
            color: $primaryColor;
            line-height: 1;
        }
    }
}

.snackbarWrapper {
    &.MuiAlert-standardSuccess {
        background-color: $primaryColor;
        color: $COLOR_WHITE;
        box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.18);

        .MuiAlert-icon {
            color: $COLOR_WHITE;
        }
    }
}
