@import '@styles/styles.scss';

.infosHeader {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 40px;
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 20px;
    text-align: center;

    &:after {
        content: '';
        width: 100%;
        height: 1px;
        background-color: $COLOR_SMOKEGREY;
        margin: 40px 30px 10px;
    }

    .headline {
        display: flex;
        align-items: center;
        margin-bottom: 15px;

        .title {
            font-size: $FONTSIZE_40;
        }

        .iconHead {
            margin-right: 20px;
        }

        .tickIco,
        .processIco {
            color: $COLOR_GREEN;
        }

        .cancelIco {
            color: $COLOR_DANGERRED;
        }

        .waitingIco {
            .fillArea {
                fill: $primaryColor;
                stroke: $primaryColor;
            }
        }
    }

    .message {
        font-size: $FONTSIZE_24;
    }

    @include mobile {
        .headline {
            .title {
                font-size: $FONTSIZE_30;
            }

            .iconHead {
                width: 48px;
            }
        }

        .message {
            font-size: $FONTSIZE_16;
        }
    }
}
