@import '@styles/variables.scss';

.itemTag {
    font-size: $FONTSIZE_16;

    button {
        border-radius: 0;
        background-color: $COLOR_EMERALD;
    }
}
