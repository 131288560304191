@import '@styles/styles.scss';

button.btn.btnResend {
    background: $COLOR_WHITE;
    border-radius: 0;

    &:hover {
        background: $COLOR_WHITE;
    }
    .text {
        color: $primaryColor;
    }
}
