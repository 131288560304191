@import '@styles/styles.scss';

.resultedWrapper {
    .mainInfosWrapper {
        padding: 40px 30px 0;
    }

    .orderInfos {
        margin-top: 0;
    }
}
