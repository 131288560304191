@import '@styles/styles.scss';

.stepper {
    min-width: 244px;
    background-color: $COLOR_MERCURY_GREY;

    .stepItm {
        padding: 15px;
        color: #d1d1d1;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .stepText {
            .stepIndex {
                margin-bottom: 5px;
                font-size: $FONTSIZE_16;
            }
            .stepName {
                font-size: $FONTSIZE_20;
            }
        }

        .iconChevronRight {
            margin-left: 10px;
            .fillArea {
                fill: #d1d1d1;
                stroke: #d1d1d1;
            }
        }

        &.completedStep {
            color: $primaryColor;
            .fillArea {
                fill: $primaryColor;
                stroke: $primaryColor;
            }
        }

        &.currStep {
            color: $primaryTextContrast;
            background: linear-gradient(252.08deg, #00aeff 0%, #0064c4 100%);

            .fillArea {
                fill: $primaryTextContrast;
                stroke: $primaryTextContrast;
            }
        }
    }

    @include tablet {
        width: 164px;
        .stepItm {
            .stepText {
                .stepIndex {
                    font-size: $FONTSIZE_14;
                }
                .stepName {
                    font-size: $FONTSIZE_16;
                }
            }
        }
    }

    @include mobileLarge {
        display: flex;
        margin-bottom: 0;
        justify-content: space-between;
        width: 100%;
        .stepItm {
            width: 100%;
            padding: 10px;
        }

        .iconChevronRight {
            display: none;
        }
    }

    @include mobileSmall {
        .stepItm {
            padding: 5px;
            .stepText {
                .stepIndex {
                    font-size: $FONTSIZE_10;
                }
                .stepName {
                    font-size: $FONTSIZE_BODY;
                }
            }
        }
    }
}
