@import '@styles/styles.scss';

.amountInfosWrapper {
    margin-top: 0;

    .mainInfosItemsWrapper {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        @include tablet {
            display: flex;
            flex-direction: column;
        }
    }
    .leftInfos {
        @include desktop {
            margin-right: 30px;
        }
    }
    .rightInfos {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-end;

        @include tablet {
            margin-top: 25px;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
        }
    }

    .remindMsg {
        &.remindMsgRight {
            text-align: right;

            @include tablet {
                text-align: left;
            }
        }
    }
}
