@import '@styles/styles.scss';

.btnCopyWrapper {
    .btnCopy.MuiButton-text {
        font-size: 14px;
        height: 26px;
        min-height: auto;
        width: 75px;
        min-width: 75px;
        border-radius: 0;
        width: 100%;
        padding: 8px;
        border: 1px solid $primaryColor;
        background: $COLOR_LIGHTBLUE;

        .MuiButton-label {
            display: flex;
            flex-direction: row-reverse;
        }

        .iconCopy {
            width: 16px;
            height: 16px;
            margin-right: 5px;
            .fillArea {
                fill: $primaryColor;
            }
        }
    }
}
