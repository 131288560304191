@import '@styles/styles.scss';

.orderInfos {
    background-color: $infosBkColor;
    padding: 30px;

    .infosTitle {
        display: flex;
        justify-content: center;
        font-size: $FONTSIZE_32;
        font-weight: bold;
        margin-bottom: 26px;
    }

    .infosWrapper {
        .buyUnit,
        .sellUnit {
            .fieldLabel {
                font-size: $FONTSIZE_18;
            }

            .field {
                font-weight: bold;
                font-size: $FONTSIZE_30;
                color: $COLOR_RED;
            }
        }
    }

    @include tablet {
        .infosTitle {
            font-size: $FONTSIZE_26;
        }
    }

    @include mobile {
        padding: 25px 13.5px;
        .infosTitle {
            font-size: $FONTSIZE_22;
        }
    }
}
