@import '@styles/styles.scss';

.qrBoardInfosWrapper {
    .chipGroupWrapper {
        .itemTagWrapper {
            @include tablet {
                display: flex;
                flex-direction: column;
            }
            .btnCopyWrapper {
                @include tablet {
                    position: static;
                    margin-top: 5px;
                }
            }
        }
    }
}
