@import '@styles/styles.scss';

.itemField {
    &.sellerName {
        .fieldLabelWrapper,
        .fieldValue {
            margin-bottom: 8px;
        }
    }

    &.unitCount {
        .fieldValue {
            margin-right: 0;
        }
    }
}
