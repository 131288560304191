@import '@styles/styles.scss';

.headerWrapper {
    .mainHeaderWrapper {
        display: flex;
        justify-content: center;
        position: relative;
        padding-top: 26px;
        padding-bottom: 26px;
        margin-bottom: 50px;

        // &:after {
        //     content: '';
        //     position: absolute;
        //     left: 0;
        //     bottom: 0;
        //     height: 2px;
        //     width: 100%;
        //     box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
        // }

        .contentHeaderWrapper {
            display: flex;
            align-items: flex-start;
            width: 1200px;
            margin: 0 20px;
        }

        .itemField.regNo {
            margin-bottom: 8px;
        }
    }

    @include mobileLarge {
        .mainHeaderWrapper {
            padding-top: 15px;
            padding-bottom: 8px;
            margin-bottom: 20px;
        }
        .companyInfosWrapper {
            margin-bottom: 25px;

            .remarkMsgWrapper .remarkMsg {
                font-size: $FONTSIZE_14;
            }
        }
    }
}
