@import '@styles/styles.scss';

.wrapper {
    &.sellPaying,
    &.buyPaying {
        .infosGroupWrapper {
            position: relative;
            @include dividerBottom();
        }
    }

    &.sellPayingTwoStepSimple,
    &.buyPayingManual {
        .itemField {
            &.realAmount,
            &.unitCount {
                .itemFieldLeft {
                    .fieldLabelWrapper {
                        min-width: auto !important;
                    }
                }
            }
        }
    }
}
